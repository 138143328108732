.bg-grey {
  background: rgba($gray-120, 0.6);
}

.icon-person-active {
  float: left;
  width: 9px;
  height: 14px;

  margin: 3px 10px 0 0;
}
.icon-person {
  float: left;
  width: 9px;
  height: 14px;

  margin: 0 10px 0 0;
}
.icon-warning {
  float: left;
  width: 19px;
  height: 22px;

  margin-left: 15px;
  margin-right: 12px;
}
.icon-help {
  display: inline-block;
  width: 19px;
  height: 22px;

  margin-bottom: -4px;
  margin-left: 8px;
  margin-right: 8px;
}
.icon-group {
  float: left;
  width: 23px;
  height: 19px;

  margin: 10px 10px 10px 0;
}
.icon-group-table-person {
  float: left;
  width: 11px;
  height: 18px;

  margin: 8px 10px 0 0;
}
.icon-email-status {
  display: inline-block;
  width: 25px;
  height: 19px;
  margin-left: 15px;
  margin-bottom: -7px;

  background-size: 75px;
}
.icon-email-status.unverified {
  background-position: -25px 0px;
}
.icon-email-status.failed {
  background-position: -50px 0px;
}

