// ISAAC
.question-panel, .question-panel > .examboard-special-tabs {
  > .content-chunk > .content-value {
    background-color: white;
    box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
    margin: auto auto 2rem auto;
    padding: 3rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem;
    }

    font-family: $secondary-font;
    font-size: 1.25rem;
    line-height: 1.6;
    font-weight: 600;
  }
}

.unit-dropdown button {
  min-width: auto;
}

button {
  -webkit-appearance: none;
  border: none;
  outline: none !important;
}

.question-actions {

  &.question-actions-leftmost {
    margin-left: auto;
  }

  .question-actions-icon {
    float: right;
    height: 3rem;

    &:focus {
      outline: none;
    }
  }

  .question-actions-link-box {
    float:right;
    border: 1px solid gray;
    height: 3.6rem;
    border-right: none;
    padding-right: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: -1.9rem;
    background: white;

    .question-actions-link {
      padding-left: 3px;
      padding-right: 3px;
      margin-left: 1rem;
      font-style: italic;
    }
  }
}

.question-component {
  margin-bottom: 2rem;
  clear: both;

  .question-content {
    font-family: $secondary-font;
    font-size: 1.25rem;
    font-weight: 400;
    line-height: 1.6;
  }

  .custom-control-label {
    &:before, &:after {
      width: 1.5rem;
      height: 1.5rem;
      margin-top: -0.25rem;
    }
  }
}

.numeric-question {
  .numeric-help {
    min-width: 4rem !important;
  }

  .unit-selection {
    .dropdown-menu {
      width: 100%;
      min-width: unset;
    }
    button.btn {
      padding: 0.25rem 1.5rem;
      min-width: unset;
      width: 100%;
      &.selected {
        border-radius: 0;
      }
      &.dropdown-toggle::after {
        position: absolute;
        right: 0.5rem;
        top: 1rem;
      }
    }
  }
}

.symbolic-question, .equality-page {
  .eqn-editor-preview {
    border: solid 1px $black;
    padding: 0.15em 0.6em;
    font-size: 1.5rem;
    cursor: default;

    &.empty {
      color: $gray-500;
    }
  }

  .equality-page {
    .eqn-editor-preview {
      background: $white !important;
    }
  }

  .eqn-editor-input input {
    font-family: monospace;
    height: auto;
  }

  .eqn-editor-help {
    min-width: 0;
  }

  .eqn-editor-symbols {
    font-size: 0.75rem;
    margin: 10px 0;

    & pre {
      font-size: 0.75rem;
      display: inline;
      white-space: pre-wrap;
      background: inherit;
      border: 0;
      padding: 0;
    }
  }
}

#inequality-help {
  min-width: 0;
}

@include media-breakpoint-up(md) {
  .parsons-layout {
    -webkit-animation: gutter-overflow 1s; /* Safari 4+ */
    -moz-animation:    gutter-overflow 1s; /* Fx 5+ */
    -o-animation:      gutter-overflow 1s; /* Opera 12+ */
    animation:         gutter-overflow 1s; /* IE 10+, Fx 29+ */
    @include make-col-offset(-2, 8);
    width: 150%;
  }
}

.parsons-question {
  .parsons-items {
    border: solid 1px #00000021;
    padding: 0 0.5em;

    &.empty {
      padding: 0.875em 0.5em;
    }

    .parsons-item > pre {
      margin: 0.5rem 0;
      padding: 0.5em 1em;
      cursor: grab; // Doesn't work?
    }

    // REMINDER: If you change the PARSONS_MAX_INDENT and PARSONS_INDENT_STEP
    // constants, you also have to change these two in here.
    $parsons-max-indent: 3;
    $parsons-step: 45;

    .parsons-item {
      @for $i from 0 through $parsons-max-indent {
        &.indent-#{$i} {
          margin-left: #{$i * $parsons-step}px;
        }
      }

      position: relative;

      .controls {
        display: none;
      }

      .show-controls {
        .controls {
          position: absolute;
          right: 0.5rem;
          top: 0.5rem;
          display: block;

          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            cursor: pointer;

            &.show:hover {
              background-color: $primary !important;
            }

            &.hide {
              opacity: 0.2;
              cursor: default;
            }

            &.show {
              opacity: 1.0;
            }

            &.reduce {

              margin-right: 4px;
            }

            &.increase {

            }
          }
        }
      }
    }

    &#parsons-choice-area {
      @for $i from 0 through $parsons-max-indent {
        &.ghost-indent-#{$i} {
          background: linear-gradient(to right,
            white #{$i * $parsons-step + 5}px, $gray-200 #{$i * $parsons-step + 5}px, $gray-200 #{$i * $parsons-step + 7}px, white #{$i * $parsons-step + 7}px
          );
        }
      }
    }
  }
}

.inequality-results {
  pre {
    white-space: pre-wrap;
  }
}

.quick-question {
  button {
      box-shadow: none !important; // How horrible...

      &.active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
  }
  .alert {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.validation-response-panel {
  text-align: center;
  border-top: solid 1px $gray-107;
  border-bottom: solid 1px $gray-107;
  &.correct {
    background: $primary;
    color: black;
    margin-left: -3rem;
    margin-right: -3rem;
    margin-bottom: -3rem;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
    @include media-breakpoint-down(sm) {
      margin-left: -1rem;
      margin-right: -1rem;
      margin-bottom: -1rem;
    }
  }
  p {
    margin-bottom: 0;
  }
}

// NOMENSA question.scss
.question-component {
  background-color: white;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.08);
  padding: 1rem;
}
