.group-item {

  + .group-item {
    border-top: $gray-107 solid 1px;
  }
  .card {
    //margin-top: 1rem;
    //margin-bottom: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background: rgba($gray-107, 0.4);
  }
}

.member-info-item {
  .icon-group-table-person {
    width: 14px;
  }
  + .member-info-item {
    border-top: $gray-107 solid 1px;
  }
}

.not-authorised {
  -webkit-filter: grayscale(100%);
  -webkit-filter: grayscale(1);
  filter: grayscale(100%);
  filter:gray;
  opacity: .5;
  .struck-out {
    text-decoration: line-through;
  }
}
