.book-chapter-options {
  text-align: center;
  font-size: 14px;
}

.book-chapter-options dl {
  display:inline-block;
  min-width: 250px;
}

.book-chapter-options dt {
  float:left;
  font-size:12px;
  line-height:26px;
  width:45px;
  height:48px;
  margin-top:4px;
}

.book-chapter-options dd {
  margin: 0;
  margin-top:4px;
  padding-left:10px;
  float:left;
  font-size:12px;
  line-height:26px;
  height:80px;
  width:220px;
  text-align: left;
}

.book-cover {
  display: block;
  margin: 0 auto 15px;
  width: 200px !important;
  height: 300px !important;
  border: 1px solid #cccccc;
}

.book-levels {
  margin: 18px -0.625rem 0 -0.625rem;

  h4 {
    font-family: exo_2bold_italic, sans-serif;
  }

  button {
    text-align: left;
    float: left;
    position: relative;
    width: 100%;
    color: $primary;
    font-size: 22px;
    font-family: 'exo_2semi_bold', sans-serif !important;
    line-height: 1.2;
    font-weight: 600;
    border-radius: 3px;
    background-color: #e3e3e3;
    padding: 20px 15px 19px 70px;
    overflow: hidden;

    .icon-stack {
      position: absolute;
      top: 8px;
      left: 15px;
    }

    &:hover {
      color: #fff;
      background-color: $primary;
    }
    &:focus {
      color: #fff;
      background-color: $primary;
    }

    .chapter-subheading {
      font-size: 0.8rem;
      margin-left: 1em;
    }
  }
}

svg.book-contents-icon {
  width:40px;

  text {
    font-family: 'exo_2semi_bold', sans-serif;
    fill: white;
    text-anchor: middle;
  }
}

svg.book-page-icon {
  width:40px;
  height:50px;

  polygon {
    fill: $primary;
  }

  text {
    font-family: 'exo_2semi_bold', sans-serif;
    fill: white;
    text-anchor: middle;
  }
}
// fix alignment in Firefox and IE
@-moz-document url-prefix() {
  .book-levels {
    button {
      .icon-stack {
        top: 5px;
      }
      .count {
        line-height: 2.4;
      }
    }
  }
  .book-skill-level {
    .icon-hexagon {
      line-height: 0.6;
    }
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .book-levels {
    button {
      .icon-stack {
        top: -40px;
      }
      .count {
        line-height: 1.9;
      }
    }
  }
  .book-page-icon polygon {
    top: -50px;
  }

  .book-chapter-options svg {
    top: -20px;
  }
  .book-skill-level {
    .count {
      top: 11px;
    }
  }
}
@include media-breakpoint-up(md) {
  .book-intro {
    margin-bottom: 10px;
  }
  .book-cover {
    float: right;
    display: inline-block;
    margin-bottom: 0;
    margin-left: 15px;
  }
  .book-levels {
    margin-top: 8px;
  }
}
