@font-face {
  font-family: 'desyrel';


  font-weight: normal;
  font-style: normal;
}

// NOMENSA fonts.scss
// REMEMBER TO REPLACE "./assets/fonts" with "/assets/fonts"
@font-face {
  font-family: 'exo_2black_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2semi_bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2medium_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_light_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2light_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2thin_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2black';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2bold';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_bold';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2black_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2semi_bold_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2medium_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_light_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2light_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2thin_italic';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2black';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2bold';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_bold';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2medium';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2semi_bold';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2extra_light';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2light';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2regular';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'exo_2thin';
  font-style: normal;
  font-weight: normal;



}

@font-face {
  font-family: 'arconrounded-regular';
  font-weight: normal;
  font-style: normal;



}
