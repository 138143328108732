// ISAAC
.content-summary-link .content-summary-link-title {
  background: none;
  border: 0;
  .text-secondary {
    font-family: $secondary-font-semi-bold;
  }
}

.new-tab {
  height: 24px;
  width: 24px;
}

.content-summary-link a {
  text-decoration: none;
}

.content-video-container {
  position: relative;
  padding-bottom: 56.25%;
  margin-bottom: 1rem;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    margin-bottom: 2rem;
  }
}

.spinner-lg {
  width: 3rem;
  height: 3rem;
}

.email-preview-frame {
  width:100%;
  height:800px;
  border:solid thin #e3e3e3;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.isaac-checkbox {
  text-align: center;
  transform: scale(1.5);
}

.table-title {
  text-align: center;
}

// NOMENSA login.scss
button.btn.btn-link.login-b,
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }
}

.login {
  display: inline-block;

  a {
    display: block;
  }
}

// NOMENSA signup.scss
.signup {
  display: none;

  @include respond-above(sm) {
    display: inline-block;
  }

  a {
    font-size: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

// ISAAC after final Nomensa delivery we can reduce duplication
.login a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }

}

.my-account a {
  color: $black;
  font-family: $primary-font;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;
  text-transform: uppercase;
  position: initial !important;
  display: unset !important;

  &:hover {
    @include respond-above(sm) {
      text-decoration: underline;
    }
  }

  span {
    border: 0;
    display: block;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    clip: initial;
    height: initial;
    position: relative;
    width: initial;
  }

}

.logout {
  display: none;

  @include respond-above(sm) {
    display: inline-block;
  }

  a {
    font-size: 0.875rem;
    text-decoration: none;
    text-transform: uppercase;
    position: initial !important;
    display: unset !important;

    &:hover {
      @include respond-above(sm) {
        text-decoration: underline;
      }
    }
  }
}

.share-link-icon {

  width: 50px;
  height: 58px;
  background-repeat: no-repeat;
  z-index: 1;
  float: right;
  position: relative;
  margin-right: 5px;
  margin-left: 10px;
  background-color: transparent;
}
.share-link {
  font-family: $secondary-font !important;
  display: none;
  position: relative;
  padding: 4px 8px;
  padding-right: 32px;
  border: 1px solid gray;
  right: 30px;
  background: #fff;
  height: 58px;
  input {
    position: relative;
    top: -45px;
    white-space: nowrap;
    text-overflow: ellipsis;
    user-select: all;
    -webkit-user-select: all;
    -moz-user-select: all;
    -ms-user-select: all;
    width: 100%;
    padding-left: 2px;
  }
  &.double-height {
    height: 118px;
  }
}

.print-icon {

  width: 50px;
  height: 58px;
  background-repeat: no-repeat;
  z-index: 1;
  float: right;
  position: relative;
  margin-right: 10px;
  margin-left: 5px;
  background-color: transparent;
}
.not-mobile {
  @include respond-below(sm) {
    display: none;
  }
}

.pointer-cursor {
  cursor: pointer;
}

.grab-cursor {
  cursor: grab;
}

.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-30 {
  width: 30% !important;
}

.w-40 {
  width: 40% !important;
}

.overflow-visible {
  overflow: visible !important;
}

// OLD NOMENSA TO ALTER INTO ISAAC
//a, button {
//  &.btn.btn-link.login-b {
//    color: $black;
//    font-family: $primary-font;
//    font-weight: 400;
//    text-decoration: underline;
//
//    &::before {
//      @include pseudo-element();

//      background-repeat: no-repeat;
//      background-size: contain;
//      height: 24px;
//      width: 24px;
//
//      @include respond-above(sm) {
//        display: none;
//      }
//    }
//
//    span {
//      border: 0;
//      clip: rect(0, 0, 0, 0);
//      display: block;
//      height: 1px;
//      overflow: hidden;
//      padding: 0;
//      position: absolute;
//      white-space: nowrap;
//      width: 1px;
//
//
//      @include respond-above(sm) {
//        clip: initial;
//        height: initial;
//        position: relative;
//        width: initial;
//      }
//    }
//  }
//}
