#filter-panel {
  margin-bottom: 42px;
  #expand-filter-button {

    &.open {

    }
    display: block;
    height: 34px;
    width: 40px;
    left: 48%;
    bottom: -32px;
    position: absolute;
    background-position: 50% top;
    background-repeat: no-repeat;
    cursor: pointer;
    z-index: 10;
  }
  .filter-go-to-questions {
    color: $secondary;
    display: block;
    width: 180px;
    margin-left: -90px;
    text-align: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    border: 1px solid #509e2e;
    border-bottom: none;
    padding: 0.25rem 0.75rem;;
    border-radius: 10px 10px 0 0;
    font-weight: bold;
  }
  .filter-action {
    text-decoration: underline;
    &:hover, &:focus {text-decoration: none;}
  }
}

.hexagon-tier-title, .hexagon-level-title, .hexagon-tier-summary {
  pointer-events: none;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: $secondary-font-semi-bold;
}
.hexagon-level-title {
  font-weight: 600;
  font-size: 1rem;
  &.active {color: white;}
}
.hexagon-tier-title {
  font-weight: 600;
  font-size: 0.8rem;
  color: #333;
  hyphens: manual;
  &.active {color: white;}
  &.small {
    font-size: 0.75rem;
    overflow-wrap: anywhere;
  }
}
.hexagon-tier-summary {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.8rem;
  font-weight: 100;
}
