// ISAAC temp hopefully
small {
  border: 0 !important;
}

code {
  display: inline-block;
  color: unset;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
  background: #fff;
  margin: 0 -0.05rem;
  padding: 0 0.05rem;
}

.text-muted {
  a {
    color: $text-muted !important;
  }
}

ul {
  list-style-position: outside;
  &.no-bullet {
    list-style-type: none;
    list-style-image: none;
  }
}

// NOMENSA typography.scss
// REPLACE ./assets WITH /assets
p {
  &.p-small {
    font-size: 0.8125em;
    line-height: 1.69;
  }
}

a, div.isaac-nav-link {
  color: $gray-194;
  display: inline-block;
  text-decoration: underline;

  &:hover {
    color: $gray-194;
    text-decoration: none;
  }

  &.a-alt {
    color: $purple;
  }

  &.lrg-text {
    font-size: 1.125rem;
  }

  &.x-lrg-text {
    font-size: 1.25rem;

    @include respond-above(sm) {
      font-size: 1.625rem;
    }
  }

  &.next-link {
    padding-right: 1.2rem;
    background-repeat: no-repeat;
    background-position: center right;
    clear: right;
    text-decoration: none;
  }
  &.previous-link {
    padding-left: 1.2rem;
    background-repeat: no-repeat;
    background-position: center left;
    clear: left;
    text-decoration: none;
  }
}

.btn {
  &.btn-sm {
    &.a-alt {
      color: $purple;
      font-family: $secondary-font-medium;
      text-decoration: underline;
    }
  }
}

blockquote {
  &.blockquote {
    border-left: 0.25rem solid $gray-107;
    padding: 0.5rem 1rem;
  }
}

small {
  line-height: 1.69;
  border-top: 1px solid $black;
}

hr {
  display: block;
  border-bottom: 1px solid $black;
  position: relative;

  &::before {
    @include pseudo-element;
    border-bottom: 5px solid $black;
    bottom: -3px;
    left: 0;
    max-width: 158px;
    right: 0;
    width: 32%;

    @include respond-above(sm) {
      max-width: 380px;
    }
  }

  &.text-center {
    &::before {
      margin: auto;
    }
  }
}

.user-select-all {
  user-select: all;
}

.text-decoration-skip-none {
  text-decoration-skip: none;
  text-decoration-skip-ink: none;
}
