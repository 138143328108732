.editor {
  background-color: white;
  border: solid 1px #00000021;
  padding: 11.5px;
  //font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  //font-size: 0.875rem;
  max-height: 15.4rem;

  .cm-editor {
    outline: none !important;
  }
}

.output-terminal-container {
  height: 200px;
  background-color: #000000;
  border-radius: 5px;
  padding: 10px 10px 10px 20px;
  position: relative;
  overflow: hidden;
}

.run-button, .check-button {
  height: 50px;
  padding-right: 5px;
  padding-left: 5px;
}

.run-arrow {
  background-image: url("run_arrow.svg");
  width: 100px;
  height: 100px;
  background-size: cover;
}

.feedback-banner {
  font-family: Consolas, monospace;
  margin: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: auto;
  overflow-y: hidden;
  color: white;
  white-space: nowrap;
  padding-left: 30px !important;
  z-index: 99;

  .feedback-button {
    background: 0 0;
    font-weight: 700;
    font-size: 120%;
    margin-top: -0.2rem;
    position: absolute;
    left: 3px;
    opacity: 1;
    text-shadow: 0 1px 0 #FFFFFF55;
    color: white;
  }
}

.feedback-success {
  background-color: $green;
}

.feedback-error {
  background-color: $red;
}


