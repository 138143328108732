#account-page {
  .card {
    box-shadow: 0 2px 30px 0 $shadow-08;
    border: none;
  }
  .card-footer {
    background-color: white;
    border-color: $gray-118;
  }

  .selection-area{
    overflow: auto;
    max-height: 180px;
  }

  legend {
    font-size: 1rem;
  }
}

.react-select-error {
  box-shadow: 0 0 0 1px #dc3545 !important;
  outline: none !important;
  border-radius: 3px;
  .select__control {
    border: none !important;
  }
}

.invalid-school {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.form-group.school {
  position: relative;

  .school-input {
    position: relative;
  }

  .school-search-results {
    position: absolute;
    z-index: 1;
    margin-top: -0.7rem;
    padding: 0;
    width: 100%;
    border: 1px solid black;
    border-top: 1px solid $gray-103;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    @include respond-above(sm) {
      background-color: $white;
      box-shadow: 0 20px 30px 0 $shadow-08;
      top: 5rem;
    }

    li {
      list-style-type: none;
      padding: 0.5em 1em;
      margin: 0;
      background: white;
      cursor: default;
      &:hover {
        background: $primary;
      }
    }
  }
}

.connect-list {
  border: #333 1px solid;
  background-color: #fff;
  padding: 0;

  h3 {
    font-size: 1rem;
    color: #fff;
    background-color: #333;
    padding: 1rem;
    margin: 0;
  }

  .remove-link {
    text-align: center;
    margin: 0;
    padding: 0.5rem;
    border-top: 1px solid rgba(#333, 0.2);
    a {
      font-weight: bold;
      color: $danger;
    }
  }
}
.teachers-connected,
.teachers-disconnected {
  margin: 1rem;
  padding-bottom: 0;

  li {
    list-style: none;
    font-size: 14px;
    margin-bottom: 5px;
  }
  .icon-person,
  .icon-person-active {
    margin-top: 2px;
  }
}
.teachers-disconnected li {
  color: #999;
}
.revoke-teacher {
  float: right;
  color: #669933;
  font-weight: 600;
}
.reconnect-teacher {
  float: right;
  color: #999;
  font-weight: 600;

  &:hover {
    color: #666;
  }
}

.my-groups-table-section {
  table {
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #333;
    margin-bottom: 0;
    th{
      background-color: #333;
      color:#fff;
      font-size:1rem;
    }
    td li {
      color: #858585;
      font-size: .875rem;
      text-align: left;
    }
  }
  .my-groups-table-row-container {
    max-height: 300px;
    overflow-y: auto;
    margin-bottom: 1.25rem;
    border: #e3e3e3 1px solid;
    border-top: 0;
    table {
      border: none;
    }
    & ul {
      margin-bottom: 0px;
    }
  }
}

.linked-account-button {
  border: none;
  width: 46px;
  height: 46px;

  &.google-button {

  }
}

.qrcode-mfa {
  img {
    width: 80%;
  }
}
