// From NOMENSA global.scss
.search--filter-group.form-group input.form-control {
  border-width: 0.5px !important;
  border-color: $gray-160;
}

/** Focus styles **/

.form-control:focus {
  box-shadow: none;
  outline: 0.2rem solid $focus-blue;
}

.form-control-file:focus {
  outline: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 5px;
}

.form-check-input:focus {
  outline: 0.2rem solid $focus-blue;
}

.page-link:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 3px;
}

.btn.btn-link:focus {
  box-shadow: 0 0 0 0.2rem $focus-blue !important;
  outline: none !important;
  border-radius: 3px;
}

.navbar-toggler:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
  border-radius: 3px;
}

.link-list.a:focus {
  box-shadow: none !important;
  outline: 0.2rem solid $focus-blue !important;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: 0.2rem solid $black;
}

.nav-link.active:focus {
  box-shadow: none;
  outline: 0.2rem solid $secondary;
}

.btn-action:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 2px;
  outline: none !important;
}

.btn.btn-outline-primary:focus {
  box-shadow: 0 0 0 2pt $secondary !important;
  border-radius: 8px;
  outline: none !important;
}

.btn-secondary:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 8px;
  outline: none !important;
}

.book-chapter:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 1px;
  outline: none !important;
}

.nav-link:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 1px;
  outline: none !important;
}

.book-link:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 0;
  outline: none !important;
}

.btn-danger:focus {
  box-shadow: 0 0 0 2pt black !important;
  border-radius: 8px;
  outline: none !important;
}

.login-b:focus {
  box-shadow: none;
  border: 0.2rem solid $focus-blue;
}

.btn-outline-primary:focus {
  border-color: $secondary !important;
}

.btn.btn-sm.a-alt:focus {
  box-shadow: 0 0 0 0.2rem $focus-blue !important;
  outline: none !important;
  border-radius: 3px;
}

.footerLink:focus {
  box-shadow: 0 0 0 2pt white;
  border-radius: 3px;
  outline: none !important;
}

.footerTop a:focus {
  outline: 0.2rem solid white;
}

// Close modal button
.close:not(:disabled):not(.disabled):focus {
  box-shadow: none;
  outline: 0.2rem solid $focus-blue;
}

::placeholder { // Firefox, Chrome, Opera
  color: $gray-160 !important;
}

:-ms-input-placeholder { // Internet Explorer 10-11
  color: $gray-160 !important;
}

::-ms-input-placeholder { // Microsoft Edge
  color: $gray-160 !important;
}

:focus {
  outline: 0.2rem solid $black;
}

// TODO: Review blanket suppression of focus indicator on keyboard-unfocusable elements.
[tabindex="-1"]:focus {
  outline: 0 !important;
}

/** End of focus styles **/
